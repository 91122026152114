<script>
import { countries } from "@/config/api/countries";
export default {
  props: ["selectedCountry"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      console.log(this.selectedCountry);
      if (this.selectedCountry) {
        this.updateCountry(data, this.selectedCountry._id);
      } else {
        this.addCountry(data);
      }
    },
    addCountry(data) {
      this.performAction = true;
      const api = countries.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("CountryAction");
          this.$bvToast.toast("Country added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Country is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateCountry(data, id) {
      this.performAction = true;
      const api = countries.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Country updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Country is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
          this.$emit("closeModal");
        });
    },
  },
  watch: {
    selectedCountry(val) {
      console.log("selected Country");
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Country"
    @hidden="$emit('resetModal')"
    :title="selectedCountry ? 'Update Country' : 'Add Country'"
    hide-footer
  >
    <FormulateForm
      name="CountryAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedCountry"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedCountry"> Update </span>
          <span v-else> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>
