<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { countries } from "@/config/api/countries";
import CountryModal from "@/components/countries/country-modal";
import City from "@/views/pages/cities";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Admin |",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, CountryModal, City },
  data() {
    return {
      title: "Countries",
      breadcrumbs: [
        {
          text: "Countries",
          active: true,
        },
        {
          text: "",
          //   to: { name: "home" },
        },
      ],
      countryData: [],
      selectedCountry: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "country", sortable: true },
        { key: "createdAt", sortable: true },
        { key: "updatedAt", sortable: true },
        { key: "actions", sortable: false },
        { key: "show_details", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.countryData.length;
    },
    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    deletedCountries() {
      return this.countryData.filter((email) => email.deletedAt != null);
    },
    activeCountries() {
      return this.countryData.filter((email) => email.deletedAt === null);
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.countryData.length;
  },
  methods: {
    toggleDetails(val) {
      val.toggleDetails();
      this.currentCountry(val);
    },
    async loadData() {
      this.isBusy = true;
      const api = countries.get;
      await this.generateAPI(api)
        .then((res) => {
          res.data.countries.map((i) => {
            i["isActive"] = true;
          });
          this.countryData = res.data.countries.map((item) => {
            return {
              ...item,
              country: item.name,
            };
          });
          // this.$store.dispatch("countries/storeCountries", this.countryData);
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editCountry(data) {
      this.selectedCountry = data;
      this.showActionModal();
    },
    deleteCountry(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = countries.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Country deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Country is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    currentCountry(data) {
      return (this.selectedCountry = data);
    },
    showActionModal() {
      this.$bvModal.show("action-Country");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Country");
    },
    addCountry() {
      this.selectedCountry = null;
      this.showActionModal();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button
              id="addCountries"
              @click="addCountry"
              pill
              variant="primary"
              >Add Countries</b-button
            >
            <!-- All Countries -->
            <b-tabs
              nav-class="nav-tabs-custom"
              v-model="activeTab"
              active-nav-item-class="font-weight-bold"
              active-tab-class="font-weight-bold"
              content-class="mt-3"
            >
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Countries</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    v-if="this.activeTab == 0"
                    :items="countryData"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                  >
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader
                          color="#505d69"
                          :loading="true"
                        ></pulse-loader>
                      </div>
                    </template>
                    <template #cell(country)="row">
                      <strong> {{ row.value }} </strong><br />
                      <b-badge pill variant="danger" v-if="row.item.deletedAt"
                        >Deleted</b-badge
                      >
                    </template>
                    <template #cell(show_details)="row">
                      <b-button
                        pill
                        variant="primary"
                        size="sm"
                        @click="toggleDetails(row)"
                        class="mr-2"
                      >
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>
                    <template #row-details="row">
                      <b-card>
                        <City
                          :currentCountry="row.item"
                          :countriesData="countryData"
                        />
                      </b-card>
                    </template>

                    <template #cell(actions)="row">
                      <button
                        class="action-btn"
                        @click="deleteCountry(row.item)"
                      >
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button class="action-btn" @click="editCountry(row.item)">
                        <i class="ri-edit-line"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                        row.item.deletedAt !== null
                          ? moment(row.item.deletedAt).format("l")
                          : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                        row.item.deletedBy !== null
                          ? row.item.deletedBy.name
                          : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <CountryModal
      :selectedCountry="selectedCountry"
      v-on:resetModal="selectedCountry = null"
      v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal"
    />
  </Layout>
</template>

<style scoped>
#addCountries {
  float: right;
  margin-top: 10px;
}
</style>